const imageItemsForEffectPositioned =
    [ {
        src: "features_300x211.jpg",
        icon: "VerticalSplit",
        primaryText: "Moderný dizajn",
        orderId: 1,
        imgH: 211,
        imgW: 300,
        directionStart: [ "right", 0.5 ],
        lastLineH: 25,
        lineLengthX: 50,
        lineLengthY: 0,
        xInsideImg: 0,
        yInsideImg: 10,
        lineStroke: 2,
        markIn: 3,
        markOut: 10,
        styleLeftTop: { left: 360, top: 200 }
    },
    {
        src: "features3_300x224.jpg",
        icon: "PhotoLibrary",
        primaryText: "Responzívny dizajn. Webstránky vhodné pre všetky displeje.",
        orderId: 2,
        imgH: 224,
        imgW: 300,
        directionStart: [ "topLeft", 0.35 ],
        lastLineH: 25,
        lineLengthX: 25,
        lineLengthY: 60,
        xInsideImg: 5,
        yInsideImg: 10,
        lineStroke: 2,
        markIn: 23,
        markOut: 10,
        styleLeftTop: { left: 120, top: 58 }
    },
    {
        src: "desktop-mobil_300x222.png",
        icon: "Devices",
        primaryText: "Prispôsobujúce sa pre všetky displeje.",
        orderId: 3,
        imgH: 222,
        imgW: 300,
        directionStart: [ "left", 0.3 ],
        lastLineH: 25,
        lineLengthX: 50,
        lineLengthY: 0,
        xInsideImg: 10,
        yInsideImg: 10,
        lineStroke: 2,
        markIn: 10,
        markOut: 20,
        styleLeftTop: { left: 0, top: 160 }
    },
    {
        src: "mapa_300x234.jpg",
        icon: "Room",
        primaryText: "Rôzne komponenty dizajnu.",
        orderId: 4,
        imgH: 234,
        imgW: 300,
        directionStart: [ "top", 0.8 ],
        lastLineH: 25,
        lineLengthX: 50,
        lineLengthY: 30,
        xInsideImg: 5,
        yInsideImg: 10,
        lineStroke: 2,
        markIn: 22,
        markOut: 10,
        styleLeftTop: { left: 376, top: 20 }
    },
    {
        src: "galle_300x145.jpg",
        icon: "Speed",
        primaryText: "Rýchle napriek množstvu grafických prvkov.",
        orderId: 5,
        imgH: 145,
        imgW: 300,
        directionStart: [ "bottomLeft", 0.5 ],
        lastLineH: 25,
        lineLengthX: 40,
        lineLengthY: 40,
        xInsideImg: 5,
        yInsideImg: 10,
        lineStroke: 2,
        markIn: 22,
        markOut: 10,
        styleLeftTop: { left: 190, top: 240 }
    },
    {
        src: "mobil_101x200.png",
        icon: "MobileFriendly",
        primaryText: "Webstránky vyzerajú dobre aj na mobile.",
        orderId: 6,
        imgH: 200,
        imgW: 101,
        directionStart: [ "left", 0.3 ],
        lastLineH: 25,
        lineLengthX: 50,
        lineLengthY: 0,
        xInsideImg: 5,
        yInsideImg: 10,
        lineStroke: 2,
        markIn: 2,
        markOut: 10,
        styleLeftTop: { left: 12, top: 35 }
    },
    ]

const sharedForImgItems = {
    textH: 60,
    iconDistX: 10,
    iconWH: 56,
    textW: 250,
    xTextIconDistance: 10,
}

const getGroupRectangle = () => {
    let maxX = 0, minX = 0, maxY = 0, minY = 0
    const { textH, textW, xTextIconDistance, iconWH, iconDistX } = sharedForImgItems
    imageItemsForEffectPositioned.forEach((img) => {
        let maxXi = 0, minXi = 0, maxYi = 0, minYi = 0
        const { styleLeftTop, directionStart, imgW, imgH, lineLengthX, lineLengthY, markOut } = img
        const { top, left } = styleLeftTop
        const [ smer, middleRatio ] = directionStart
        const lastHHalfHeight = Math.round(Math.max(2 * markOut, iconWH, textH) / 2)
        const widthSvgIconTextOutsideImg = lineLengthX + markOut + iconDistX + iconWH + xTextIconDistance + textW

        let shiftAboveImg
        let shiftXFromImg
        let shiftUnderImg
        switch (smer) {
            case "left":
                shiftAboveImg = Math.min(middleRatio * imgH - lineLengthY - lastHHalfHeight, 0)
                minYi = top + shiftAboveImg
                maxYi = top + shiftAboveImg + imgH
                minXi = left - (widthSvgIconTextOutsideImg)
                maxXi = left + imgW
                break;

            case "right":
                shiftAboveImg = Math.min(middleRatio * imgH - lineLengthY - lastHHalfHeight, 0)
                minYi = top + Math.min(middleRatio * imgH - lineLengthY - lastHHalfHeight, 0)
                maxYi = top + shiftAboveImg + imgH
                minXi = left
                maxXi = left + imgW + widthSvgIconTextOutsideImg
                break;
            case "topLeft":
                shiftXFromImg = Math.min(middleRatio * imgW - widthSvgIconTextOutsideImg, 0)
                minXi = left + shiftXFromImg
                maxXi = left + imgW
                shiftAboveImg = - lineLengthY - lastHHalfHeight
                minYi = top + shiftAboveImg
                maxYi = top + shiftAboveImg + imgH
                break;
            case "top":
                shiftXFromImg = Math.min(middleRatio * imgW + widthSvgIconTextOutsideImg, 0)
                minXi = left
                maxXi = left + shiftXFromImg + imgW
                shiftAboveImg = - lineLengthY - lastHHalfHeight
                minYi = top + shiftAboveImg
                maxYi = top + shiftAboveImg + imgH
                break;
            case "bottom":
                shiftXFromImg = Math.min(middleRatio * imgW + widthSvgIconTextOutsideImg, 0)
                minXi = left
                maxXi = left + shiftXFromImg + imgW
                shiftUnderImg = lineLengthY + lastHHalfHeight
                minYi = top
                maxYi = top + imgH + shiftUnderImg
                break;

            case "bottomLeft":
                shiftXFromImg = Math.min(middleRatio * imgW - widthSvgIconTextOutsideImg, 0)
                minXi = left + shiftXFromImg
                maxXi = left + imgW
                shiftUnderImg = lineLengthY + lastHHalfHeight
                minYi = top
                maxYi = top + imgH + shiftUnderImg
                break;
            default:
                break;
        }
        maxX = Math.max(maxX, maxXi)
        minX = Math.min(minX, minXi)
        maxY = Math.max(maxY, maxYi)
        minY = Math.min(minY, minYi)

    })
    const grWidth = maxX - minX
    const grHeight = maxY - minY
    const grLeft = minX
    const grTop = minY
    return { grLeft, grTop, grWidth, grHeight }
}

const getAbsImagesGrouped2 = ((leftShiftAll = 428, topShiftAll = 46, dirImg = "../images/galleries/headerAnsImagesGrouped") => {
    const { textH, textW, xTextIconDistance, iconWH, iconDistX } = sharedForImgItems
    const { grLeft, grTop, } = getGroupRectangle()
    const corrImages = imageItemsForEffectPositioned.map(item => {
        const { styleLeftTop } = item
        styleLeftTop.left = styleLeftTop.left - grLeft
        styleLeftTop.top = styleLeftTop.top - grTop
        return { ...item, textH, textW, xTextIconDistance, iconWH, iconDistX }
    })
    return corrImages
})

export { imageItemsForEffectPositioned, getGroupRectangle, getAbsImagesGrouped2, sharedForImgItems }
