const ENGLISH_PHRASES = [
    ["Just keep swimming", "Finding Nemo, 2003"],
    ["I wish I knew how to quit you", "Brokeback Mountain, 2005"],
    ["What's the most you ever lost on a coin toss?", "No Country for Old Men, 2007"],
    ["Go ahead, make my day.", "Sudden Impact, 1983"],
    ["I'm walking here! I'm walking here!", "Midnight Cowboy, 1969"],
    ["You can't handle the truth!", "A Few Good Men, 1992"],
    ["I'll have what she's having.", "When Harry Met Sally, 1989"],
    ["You're gonna need a bigger boat.", "Jaws, 1975"],
    ["I'll be back.", "The Terminator, 1984"],
    ["Houston, we have a problem.", "Apollo 13, 1995"],
    ["Elementary, my dear Watson.", "The Adventures of Sherlock Holmes, 1929"],
    ["Wait a minute, wait a minute. You ain't heard nothin' yet!", "The Jazz Singer, 1927"],
    ["To infinity and beyond!", "Toy Story, 1995"],
    ["I am your father's brother's nephew's cousin's former roommate.", "Spaceballs (1987)"],
    ["Sell crazy someplace else. We're all stocked up here.", "As Good As it Gets (1997)"],
    ["McLovin? What kind of stupid name is that, Fogell? What, are you trying to be an Irish R&B singer?", "Superbad (2007)"],
    ["Anybody interested in grabbing a couple of burgers and hittin' the cemetery?", "The Royal Tenenbaums (2001)"],
    ["This is one time where television really fails to capture the true excitement of a large squirrel predicting the weather.", "Groundhog Day (1993)"]
  
  ]
  
  const GERMAN_PHRASES  = [
    "Also, ich fange dann schon mal an.",
    "Ich glaube, das kenne ich schon.",
    "Ihr wart dann auf einmal irgendwie weg.",
    "Wenn die Sonne weg ist, ist es ganz schön kalt.",
    "Hast du meine E-Mail bekommen?",
    "Das möchte ich gar nicht wissen.",
    "So was kauft man sich ja selber nie.",
    "Sieht irgendwie zu aus.",
    "Ist hier rechts vor links?",
    "Ich habe irgendwie noch gar keinen Hunger.",
    "Das geht ganz schön auf die Gelenke.",
    "Muss man auch mögen.",
    "Das habe ich als Kind gar nicht gemocht.",
    "Man soll ja täglich 2 bis 3 Liter Wasser trinken.",
    "Das sind keine Augenringe, das sind Schatten großer Taten!",
    "Nieder mit der Frühjahrsmüdigkeit! Es lebe der Winterschlaf!",
    "Bitte entschuldigen Sie, dass ich zu spät komme. Ich musste erst die Schafe aus meiner Wohnung treiben, die ich beim Einschlafen gezählt hatte.",
    "Ich spreche drei Sprachen: ironisch, sarkastisch und zweideutig."
  ]
  
//   const RUSSIAN_PHRASES = [
//     ["Какая гадость эта ваша заливная рыба! – What a nasty thing, this fish aspic of yours!", "The Irony of Fate, or Enjoy Your Bath!"],
//     ["Ну, граждане… алкоголики, хулиганы, тунеядцы… Кто хочет сегодня поработать? – Well, you alcoholics, idlers, hooligans… Who wants to work today?", "Operation Y and Shurik's Other Adventures"],
//     ["Экзамен для меня всегда праздник, профессор! – An exam is always like a holiday for me, Professor!", "Déjà vu"],
//     ["Будьте добры помедленнее, я записываю! – Talk slower, I’m writing it down.", "Kidnapping, Caucasian Style"],
//     ["Поскользнулся, упал – закрытый перелом! Потерял сознание. Очнулся – гипс  – Slipped, fell, lost consciousness – woke up in a cast.", "The Diamond Arm"],
//     ["Пойми, мир состоит из умных, из глупых, из сильных, из слабых, из тех, кто может свою судьбу повернуть, из плывущих по течению.", "Всё будет хорошо"],
//     ["Раньше мне родители что-то запрещали, щас жена... Когда я уже повзрослею?", "О чем говорят мужчины"],
//     ["Ладно, пойду, попробую. - Да всё будет хорошо. Что хорошо? Ты что, Русское радио, что ли?", "Иван Царевич и Серый Волк"],
//     ["Точно, запускай космонавта и соедени меня с Мишей. Какого космонавта? Слушай, ты привыкай уже комне. Если Израильская, то военщина. Если Советское, то шампанское, а если запускать, то космонавта.", "День выборов"],
//     ["Миша, это Эмануил Гедеонович. Какой Эмануил Гедеонович? А, здравствуйте, Эмануил Гедеонович! Я… Я вас сразу узнал. Миша, вы спите? – Нет! Вы что! Я! Никогда, я так на минутку.", "День выборов"],
//     ["Нет, спасибо, я уже был женат", "От заката до рассвета"],
//     ["Наверное, не тот год", "Назад в будущее-2"],
//     ["Заметьте, не я это предложил!", "Покровские ворота"],
//     ["Я подумаю об этом завтра!", "Унесённые ветром"],
//     ["Если человек - идиот, то это надолго!", "Бриллиантовая рука"],
//     ["Вы что, мне взятку предлагаете? Нет, деньги разменять хочу.", "Напарник"],
  
//   ]

  const RUSSIAN_PHRASES = [
    ["Какая гадость эта ваша заливная рыба!", "The Irony of Fate, or Enjoy Your Bath!"],
    ["Ну, граждане… алкоголики, хулиганы, тунеядцы… Кто хочет сегодня поработать?", "Operation Y and Shurik's Other Adventures"],
    ["Экзамен для меня всегда праздник, профессор!", "Déjà vu"],
    ["Будьте добры помедленнее, я записываю!", "Kidnapping, Caucasian Style"],
    ["Поскользнулся, упал – закрытый перелом! Потерял сознание. Очнулся – гипс.", "The Diamond Arm"],
    ["Пойми, мир состоит из умных, из глупых, из сильных, из слабых, из тех, кто может свою судьбу повернуть, из плывущих по течению.", "Всё будет хорошо"],
    ["Раньше мне родители что-то запрещали, щас жена... Когда я уже повзрослею?", "О чем говорят мужчины"],
    ["Ладно, пойду, попробую. - Да всё будет хорошо. Что хорошо? Ты что, Русское радио, что ли?", "Иван Царевич и Серый Волк"],
    ["Точно, запускай космонавта и соедени меня с Мишей. Какого космонавта? Слушай, ты привыкай уже комне. Если Израильская, то военщина. Если Советское, то шампанское, а если запускать, то космонавта.", "День выборов"],
    ["Миша, это Эмануил Гедеонович. Какой Эмануил Гедеонович? А, здравствуйте, Эмануил Гедеонович! Я… Я вас сразу узнал. Миша, вы спите? – Нет! Вы что! Я! Никогда, я так на минутку.", "День выборов"],
    ["Нет, спасибо, я уже был женат", "От заката до рассвета"],
    ["Наверное, не тот год", "Назад в будущее-2"],
    ["Заметьте, не я это предложил!", "Покровские ворота"],
    ["Я подумаю об этом завтра!", "Унесённые ветром"],
    ["Если человек - идиот, то это надолго!", "Бриллиантовая рука"],
    ["Вы что, мне взятку предлагаете? Нет, деньги разменять хочу.", "Напарник"],
  
  ]


export {ENGLISH_PHRASES,GERMAN_PHRASES,RUSSIAN_PHRASES}