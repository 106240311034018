import React, { useRef, useEffect, useState, useCallback, useMemo, useContext } from "react";
import { StaticQuery, graphql } from "gatsby"
import { emphasize } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import Box from "@mui/system/Box";
import { motion, useAnimation } from "framer-motion"
import { getGroupRectangle, getAbsImagesGrouped2, } from "../../../utils/dataAbsImagesGrouped2"
import { ENGLISH_PHRASES, GERMAN_PHRASES, RUSSIAN_PHRASES } from "../../../utils/dataPhrases"
import bgImgAfter from "../../../images/white-wood_45_432x200.jpg"
import bgImgBefore from "../../../images/white-wood_hsbHsl45.jpg"
import { NotInViewContext } from "../../_shared/AnimateScrollIntoViewport";

const numberGermanPhrases = GERMAN_PHRASES.length
const numberEnglishPhrases = ENGLISH_PHRASES.length
const numberRussianPhrases = RUSSIAN_PHRASES.length
const { grWidth, grHeight } = getGroupRectangle()

const grSemiWidth = Math.round(grWidth / 2)
const grSemiHeight = Math.round(grHeight / 2)

const styleSxWrapAbsItem = {
    // opacity: 1,
    perspective: "600px",
    transformStyle: "preserve-3d",
    "& .wrapItemSet": {
        position: "absolute",
        height: "100%",
        top: 0,
        zIndex: "unset",
        opacity: 0.9,
        cursor: "pointer",
        "&:before": {
            content: '" "',
            display: "block",
            position: "absolute",
            top: 0, right: 0, bottom: 0, left: 0,
            opacity: 0.5,
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
            inset: "0 0 0 0",
            zIndex: -1,
            transform: "scaleX(0)",
            transformOrigin: "bottom right",
            transition: "transform .3s ease",
            backgroundImage: `url(${bgImgAfter})`,
        },
        "&:hover:before": {
            content: '""',
            transform: "scaleX(0.5)",
            transformOrigin: "bottom left",
        },
        "&:after": {
            content: '" "',
            display: "block",
            position: "absolute",
            top: 0, right: 0, bottom: 0, left: 0,
            opacity: 0.5,
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            transform: "scaleX(0)",
            transformOrigin: "bottom left",
            inset: "0 0 0 0",
            zIndex: -1,
            transition: "transform .3s ease",
            backgroundImage: `url(${bgImgBefore})`,
        },
        "&:hover:after": {
            content: '""',
            transform: "scaleX(0.5)",
            transformOrigin: "bottom right",
        },
        "&.selectedSet": {
            borderStyle: "groove",
            borderColor: "yellow",
            borderWidth: "thin",
            borderRadius: "20px",
            opacity: 1,
            zIndex: 6,
            transform: 'translateZ(100px)',
            "&:before": {
                bgcolor: "secondary.main",
            },
            "&:after": {
                bgcolor: "secondary2.main",
            }
        },

        "& .imgAbs": {
            opacity: 0,
            position: "absolute",
            zIndex: 1,
            maxHeight: "100%",
        },
        "& .divSvg": {
            // opacity: 0,
            position: "absolute",
            "& .svgCircle": {
                zIndex: 2,
            },
        },
        "& .divIcon.MuiFab-root.MuiFab-primary": {
            opacity: 0,
            position: "absolute",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            borderRadius: "50%",
            padding: "5px",
            bgcolor: "warning.main",
            boxShadow: 24,
            "& .spanIcon.MuiFab-label": {
                bgcolor: "secondary.main",
                "& .MuiSvgIcon-root": {
                    userSelect: "none",
                    width: "1em",
                    height: "1em",
                    display: "inline-block",
                    fill: "currentColor",
                    flexShrink: 0,
                    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    fontSize: "1.5rem",
                }
            },
        },
        "& .paper.MuiPaper-root.MuiPaper-elevation3.MuiPaper-rounded": {
            // opacity: 0,
            p: 0.5,
            fontSize: "16px",
            margin: 0,
            alignItems: "center",
            justifyContent: "center",
            textAlign: 'center',
            display: "flex",
            transition: "transform 1s",
            position: "absolute",
            borderRadius: "20px",
            "& .listItem.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button": {
                height: "60px",
                display: "flex",
                borderRadius: "inherit",
                p: 0.5,
                "& .listItemText.MuiListItemText-root": {
                    lineHeight: 'initial',
                    opacity: 0,
                    // fontSize: [ "8px", "10px", "12px", "14px" ],
                    fontSize: [ "x-small", "small", "smaller" ],
                    boxShadow: 2,
                    borderRadius: "inherit",
                    m: "auto",
                    px: 1,
                    maxHeight: "100%",
                    overflowY: "auto",
                },
            },
        },
    },
}




const varWrapAbs = {
    initial: false,
    enter: {
        transition: {
            staggerChildren: 5,
            duration: 0,
            when: "beforeChildren",
        },
    },
    whileHover: false,
    whileTap: false,
}

const varWrapAbsItem = ({ notInView, palette, baseItemDelay, isSelected, xyOutCont }) => {
    return {
        initial: notInView ? { x: 0, y: 0 } : false,
        enter: {
            x: 0,
            y: 0,
            transition: {
                delayChildren: baseItemDelay,
                staggerChildren: 0.1,
                duration: 0.15,
            },

        },
        textChange: {
        },
        whileHover: {
            rotateX: [ 0, 10, 0 ],
            transition: {
                repeat: 1,
                repeatType: "reverse",
                staggerChildren: 0.1,
                duration: 0.2,
                times: [ 0.1, 0.9, 0.1 ],
                when: "afterChildren"
            }
        },
        whileTap: {
            scale: 0.8,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                staggerChildren: 0.1,
                duration: 0.2,
                when: "afterChildren"
            }
        },
    }
}



const varImg = ({ notInView, motionDirection }) => {
    const { smerX = "", smerY = "" } = motionDirection
    let xFrom, yFrom
    if (smerX.length > 0) {
        xFrom = smerX === "left" ? -200 : 200
    } else {
        xFrom = 0
    }
    if (smerY.length > 0) {
        yFrom = smerY === "top" ? -200 : 200
    } else {
        yFrom = 0
    }
    return {

        initial: notInView ? { x: 0, y: 0, scale: 1, opacity: 1, rotateY: 0, } : false,
        enter: {
            opacity: [ 0, 1 ],
            y: [ yFrom, 0 ],
            x: [ xFrom, 0 ],
            rotateY: 0,
            scale: 1,
            transition: {
                ease: 'circInOut',
                duration: 0.1,
            }
        },

        whileHover: {
            rotateY: 90,

            zIndex: 6,
            filter: [
                'hue-rotate(0) contrast(100%)',
                'hue-rotate(360deg) contrast(200%)',
                'hue-rotate(45deg) contrast(300%)',
                'hue-rotate(0) contrast(100%)'
            ],
            scale: 1.1,
            borderRadius: "0%",
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            rotateY: 90,

            zIndex: 6,
            filter: [
                'hue-rotate(0) contrast(100%)',
                'hue-rotate(360deg) contrast(200%)',
                'hue-rotate(45deg) contrast(300%)',
                'hue-rotate(0) contrast(100%)'
            ],
            scale: 0.8,
            borderRadius: "0%",
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
}

const varDivSvg = ({ notInView, motionDirection }) => {
    const { smerX = "", smerY = "" } = motionDirection
    let xFrom, yFrom
    if (smerX.length > 0) {
        xFrom = smerX === "left" ? -1000 : 1000
    } else {
        xFrom = 0
    }
    if (smerY.length > 0) {
        yFrom = smerY === "top" ? -300 : 300
    } else {
        yFrom = 0
    }
    return {

        initial: notInView ? { x: 0, y: 0, opacity: 1 } : { x: xFrom, y: yFrom, opacity: 0 },
        enter: {
            opacity: 1,
            y: [ yFrom, 0 ],
            x: [ xFrom, 0 ],
            transition: {
                duration: 0.25,
                staggerChildren: 0.25,
                when: "beforeChildren"
            },
        },
        whileHover: {
            zIndex: 5,
            rotateY: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.2
            }
        },
        whileTap: {
            scale: 0.8,
            zIndex: 5,
            rotateY: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.2
            }
        },
    }
}

const varCircle = ({ notInView, palette, xIn, yIn }) => {

    return {
        initial: notInView ? { strokeDashoffset: 10, cx: xIn, cy: yIn } : { strokeDashoffset: 0, cx: 0, cy: 0 },
        enter: {
            strokeDashoffset: [ 0, 10 ],
            cx: [ 0, xIn ],
            cy: [ 0, yIn ],
        },
        transition: {
            duration: 0.25,
        },
        whileHover: {
            stroke: palette.primary2.main,
            fill: palette.secondary.main,
            fillOpacity: 1,
            strokeDashoffset: [ 0, 10 ],
            strokeWidth: 5,
            pathLength: 0.5,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            scale: 0.8,
            stroke: palette.primary2.main,
            fill: palette.secondary.main,
            fillOpacity: 1,
            strokeDashoffset: [ 0, 10 ],
            strokeWidth: 5,
            pathLength: 0.5,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
}


const varSvgPath = ({ notInView, imgMainColor }) => {
    return {
        initial: notInView ? { strokeDashoffset: 10 } : { strokeDashoffset: 0 },
        enter: {
            strokeDashoffset: [ 0, 10 ],
            transition: {
                duration: 0.25
            }
        },
        whileHover: {
            strokeWidth: 5,
            strokeDashoffset: [ 0, 10 ],
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            scale: 0.8,
            strokeWidth: 5,
            strokeDashoffset: [ 0, 10 ],
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
}

const varDivIcon = ({ notInView, motionDirection }) => {
    const { smerX = "", smerY = "" } = motionDirection
    let xFrom, yFrom
    if (smerX.length > 0) {
        xFrom = smerX === "left" ? -1000 : 1000
    } else {
        xFrom = 0
    }
    if (smerY.length > 0) {
        yFrom = smerY === "top" ? -300 : 300
    } else {
        yFrom = 0
    }
    return {
        initial: notInView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x: xFrom, y: yFrom },
        enter: {
            opacity: 1,
            y: [ yFrom, 0 ],
            x: [ xFrom, 0 ],

            transition: {
                ease: 'circInOut',
                duration: 0.25,
                when: "beforeChildren"
            }
        },
        whileHover: {
            zInex: 6,
            rotateY: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.4
            }
        },
        whileTap: {
            scale: 0.8,
            zInex: 6,
            rotateY: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.4
            }
        },
    }
}

const varSpanIcon = ({ notInView, motionDirection }) => {
    // const { smerX = "", smerY = "", xText, yText } = motionDirection
    return {

        initial: notInView ? {
            // x: 0, y: 0,
            rotateZ: 360
        } : {
            // x: 0, y: 0,
            rotateZ: 0
        },
        enter: {

            rotateZ: [ 0, 360 ],
            // y: 0, x: 0,
            transition: {
                ease: 'circInOut',
                duration: 0.2,
            }
        },

        whileHover: {
            rotateY: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.2
            }
        },
        whileTap: {
            scale: 0.8,
            rotateY: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.2
            }
        },
    }
}


const varSvgIconPath = (({ notInView, palette }) => {
    return {

        initial: notInView ? { strokeDashoffset: 10, pathLength: 1 } : { strokeDashoffset: 0, pathLength: 0, pathSpacing: 0.0 },
        enter: {
            strokeDashoffset: [ 0, 10 ],
            transition: { duration: 0.25 }
        },
        exit: {
            pathLength: 0,
            pathSpacing: 0.0,
            transition: { duration: 0.2 }
        },
        whileHover: {
            strokeDashoffset: [ 0, 10 ],
            stroke: palette.primary2.main,
            fill: palette.primary.main,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            scale: 0.8,
            strokeDashoffset: [ 0, 10 ],
            stroke: palette.primary2.main,
            fill: palette.primary.main,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
})


const varPaperText = ({ notInView, motionDirection }) => {
    const { smerX = "", smerY = "" } = motionDirection
    let xFrom, yFrom
    if (smerX.length > 0) {
        xFrom = smerX === "left" ? -100 : 100
    } else {
        xFrom = 0
    }
    if (smerY.length > 0) {
        yFrom = smerY === "top" ? -100 : 100
    } else {
        yFrom = 0
    }
    return {

        initial: notInView ? { opacity: 1, y: 0, x: 0 } : { opacity: 0, y: yFrom, x: xFrom },
        enter: {
            opacity: [ 0, 1 ],
            y: [ yFrom, 0 ],
            x: [ xFrom, 0 ],

            transition: {
                when: "beforeChildren",
                ease: 'circInOut',
                duration: 0.25,
            },
        },
        whileHover: {
            scale: 1.2,
            zIndex: 6,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            scale: 0.8,
            zIndex: 6,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
}

const varDivListItem = ({ notInView, palette, styleColors }) => {
    return {
        initial: notInView ? { scale: 1, rotateY: 0, rotateX: 0 } : { scale: 0, rotateY: 0, rotateX: 45 },
        enter: {
            rotateX: [ 45, 0 ],
            rotateY: 0,
            scale: 1,
            transition: {
                duration: 0.25,
                when: "afterChildren",
            },

        },
        whileHover: {
            rotateY: 90,
            scale: 1.2,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            rotateY: 90,
            scale: 0.8,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
}

const varText = ({ notInView, palette, styleColors }) => {
    return {

        initial: notInView ? { opacity: 1, x: 0, rotateX: 0, backgroundColor: styleColors.backgroundColor, color: styleColors.color } : {
            opacity: 0, x: 0, rotateX: 90,
        },
        enter: {
            opacity: [ 0, 1 ],
            rotateX: 0,
            x: 0,
            backgroundColor: styleColors.backgroundColor,
            color: styleColors.color,

            transition: {
                duration: 0.25,
            },

        },
        textChangeBefore: {
            opacity: 0,
            rotateX: 90,
            transition: {
                duration: 0.25,
            },

        },
        textChangeAfter: {
            rotateX: 0,
            backgroundColor: styleColors.backgroundColor,
            color: styleColors.color,
            opacity: 1,
            transition: {
                duration: 0.25,
            },

        },
        whileHover: {
            rotateX: 90,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
        whileTap: {
            rotateX: 90,
            scale: 0.8,
            transition: {
                repeat: 1,
                repeatType: "reverse",
                duration: 0.5
            }
        },
    }
}




function getPositionsForAnimEffect({ grShiftX, grShiftY, imgData, coefX, coefY, coefXforShift }) {

    let { lastLineH = 30, lineLengthX, lineLengthY = 0, xInsideImg = 5, markIn = 5, markOut = 5, textH, textW, xTextIconDistance, iconWH, iconDistX, imgW, imgH } = imgData;
    const { directionStart = [ "left", 0.5 ], yInsideImg = 0, styleLeftTop = { left: 0, top: 0 }, } = imgData
    markIn = Math.round(coefX * markIn)
    markOut = Math.round(coefX * markOut)
    xInsideImg = Math.round(coefX * xInsideImg)
    lineLengthX = Math.round(coefX * lineLengthX)
    lastLineH = Math.round(coefX * lastLineH)
    const imgLeftAbs = Math.round(coefXforShift * styleLeftTop.left)

    iconDistX = Math.round(coefX * iconDistX)
    iconWH = Math.round(coefX * iconWH)
    xTextIconDistance = Math.round(coefX * xTextIconDistance)
    textW = Math.round(coefX * textW)

    let imgTopAbs
    imgTopAbs = Math.round(coefY * styleLeftTop.top)

    if (coefY > 1) {
        imgTopAbs = Math.round(1 * styleLeftTop.top + (coefY - 1) * 300)
        imgW = Math.round(1 * imgW)
        imgH = Math.round(1 * imgH)
    } else {
        imgW = Math.round(coefX * imgW)
        imgH = Math.round(coefY * imgH)
    }

    lineLengthY = Math.round(coefY * lineLengthY)
    textH = Math.round(coefY * textH)

    const imgWFit = Math.round(imgW)
    const imgWFitDif = imgWFit - imgW

    const textHalfHeight = Math.round(textH / 2)
    const iconWHalfHeight = Math.round(iconWH / 2)

    const [ smer, middleRatio ] = directionStart;

    let xIn, xOut, x2, y2, iconL, textL, svgL, svgTop, svgW, svgH;
    let yIn;
    let yOut;
    let contL, contTop, contW, contH, contBottom
    let iconTop, textTop
    let lastHHeight, lastHHalfHeight
    let imgL, imgTop
    let svgWOutsideImg
    let shiftAboveImgFromRatio = 0
    let outPartTop
    let outYShift
    let widthSvgIconText
    let smerX = "", smerY = ""
    const markMax = Math.max(markOut, markIn)
    switch (smer) {
        case "left":
            smerX = "left"
            xOut = markOut + 1
            x2 = xOut + lastLineH
            xIn = xOut + lineLengthX + xInsideImg

            svgW = xIn + markIn + 1
            svgWOutsideImg = svgW - xInsideImg - markIn;
            widthSvgIconText = svgW + iconDistX + iconWH + xTextIconDistance + textW

            imgL = widthSvgIconText - xInsideImg - markIn
            contW = widthSvgIconText - xInsideImg - markIn + imgW + 2

            contL = imgLeftAbs - imgL
            svgL = imgL - svgWOutsideImg;

            iconL = svgL - iconDistX - iconWH;
            textL = iconL - xTextIconDistance - textW;


            shiftAboveImgFromRatio = Math.round(middleRatio * imgH - lineLengthY)
            if (lineLengthY > 0) {
                smerY = "top"
                yOut = 1 + markOut;
                y2 = yOut;
                yIn = y2 + lineLengthY
                lastHHeight = Math.max(2 * markOut, iconWH, textH)
                lastHHalfHeight = Math.round(lastHHeight / 2)
                outYShift = Math.max(shiftAboveImgFromRatio, lastHHalfHeight)

                svgTop = outYShift - markOut
                svgH = yIn + markIn + 1

            } else {
                yOut = 1 + markOut
                y2 = yOut;
                yIn = y2
                lastHHeight = Math.max(2 * markIn, 2 * markOut, iconWH, textH)
                lastHHalfHeight = Math.round(lastHHeight / 2)
                outYShift = Math.max(shiftAboveImgFromRatio, lastHHalfHeight)

                svgTop = outYShift - markMax - 1
                svgH = 2 * markMax + 2

            }
            textTop = outYShift - textHalfHeight
            iconTop = outYShift - iconWHalfHeight
            outPartTop = imgTopAbs + shiftAboveImgFromRatio - lastHHalfHeight

            if (imgTopAbs > outPartTop) {
                contTop = outPartTop
                imgTop = imgTopAbs - outPartTop
                contBottom = imgTopAbs + imgTop + imgH

            } else {
                contTop = imgTopAbs
                imgTop = 0
                contBottom = imgTopAbs + imgH
            }

            contH = imgTop + imgH + 2
            break;

        case "right":
            smerX = "right"
            xIn = markIn + 1;
            x2 = xIn + xInsideImg + lineLengthX - lastLineH;
            xOut = x2 + lastLineH

            svgW = xOut + markOut + 1
            svgWOutsideImg = svgW - xInsideImg - markIn;
            widthSvgIconText = svgW + iconDistX + iconWH + xTextIconDistance + textW
            imgL = 0
            contW = imgW - xInsideImg + widthSvgIconText - markIn + 2
            contL = imgLeftAbs
            svgL = imgL + imgW - xInsideImg - markIn;
            iconL = svgL + svgW + iconDistX;
            textL = iconL + iconWH + xTextIconDistance;
            shiftAboveImgFromRatio = Math.round(middleRatio * imgH - lineLengthY)
            if (lineLengthY > 0) {
                smerY = "top"
                yOut = 1 + markOut;
                y2 = yOut;
                yIn = y2 + lineLengthY
                lastHHeight = Math.max(2 * markOut, iconWH, textH)
                lastHHalfHeight = Math.round(lastHHeight / 2)
                outYShift = Math.max(shiftAboveImgFromRatio, lastHHalfHeight)

                svgTop = outYShift - markOut
                svgH = yIn + markIn + 1

            } else {
                yOut = 1 + markOut;
                y2 = yOut;
                yIn = y2
                lastHHeight = Math.max(2 * markIn, 2 * markOut, iconWH, textH)
                lastHHalfHeight = Math.round(lastHHeight / 2)
                outYShift = Math.max(shiftAboveImgFromRatio, lastHHalfHeight)
                svgTop = outYShift - markMax - 1
                svgH = 2 * markMax + 2
            }

            textTop = outYShift - textHalfHeight
            iconTop = outYShift - iconWHalfHeight

            outPartTop = imgTopAbs + shiftAboveImgFromRatio - lastHHalfHeight

            if (imgTopAbs > outPartTop) {
                contTop = outPartTop
                imgTop = imgTopAbs - outPartTop - lastHHalfHeight
                contBottom = imgTopAbs + imgTop + imgH

            } else {
                contTop = imgTopAbs
                imgTop = 0
                contBottom = imgTopAbs + imgH
            }

            contH = contBottom - contTop + 2
            break;

        case "top":
            smerY = "top"
            smerX = "right"
            xIn = markIn + 1;
            x2 = xIn + lineLengthX - lastLineH
            xOut = x2 + lastLineH
            svgW = xOut + markOut + 1
            svgL = middleRatio * imgW - markIn
            iconL = svgL + svgW + iconDistX;
            textL = iconL + iconWH + xTextIconDistance;
            contW = Math.max(textL + textW, imgW)
            contL = imgLeftAbs + imgWFitDif
            imgL = 0

            yOut = 1 + markOut;
            y2 = yOut;
            yIn = y2 + lineLengthY + yInsideImg
            lastHHeight = Math.max(2 * markOut, iconWH, textH)
            lastHHalfHeight = Math.round(lastHHeight / 2)


            svgTop = lastHHalfHeight - markOut
            svgH = yIn + markIn + 1
            textTop = lastHHalfHeight - textHalfHeight
            iconTop = lastHHalfHeight - iconWHalfHeight

            imgTop = lastHHalfHeight + lineLengthY
            contH = imgTop + imgH + 2
            contTop = imgTopAbs - imgTop

            break;

        case "topLeft":
            smerY = "top"
            smerX = "left"
            xOut = markOut + 1;
            x2 = xOut + lastLineH;
            xIn = xOut + lineLengthX;

            svgW = xIn + markIn + 1

            widthSvgIconText = svgW + iconDistX + iconWH + xTextIconDistance + textW

            if (widthSvgIconText > middleRatio * imgW) {
                imgL = widthSvgIconText - middleRatio * imgW

            } else {
                imgL = 0
            }
            contW = imgL + imgW + 3
            contL = imgLeftAbs - imgL
            svgL = imgL + middleRatio * imgW - svgW
            iconL = svgL - iconDistX - iconWH
            textL = iconL - xTextIconDistance - textW
            yOut = 1 + markOut;
            y2 = yOut;
            yIn = y2 + lineLengthY + yInsideImg
            lastHHeight = Math.max(2 * markOut, iconWH, textH)
            lastHHalfHeight = Math.round(lastHHeight / 2)

            svgTop = lastHHalfHeight - markOut
            svgH = yIn + markIn + 1
            textTop = lastHHalfHeight - textHalfHeight
            iconTop = lastHHalfHeight - iconWHalfHeight

            imgTop = lastHHalfHeight + lineLengthY
            contH = imgTop + imgH + 2
            contTop = imgTopAbs - imgTop

            break;
        case "bottom":
            smerY = "bottom"
            smerX = "right"
            xIn = markIn + 1;
            x2 = xIn + lineLengthX - lastLineH
            xOut = x2 + lastLineH
            svgW = xOut + markOut + 1
            svgL = middleRatio * imgW - markIn
            iconL = svgL + svgW + iconDistX;
            textL = iconL + iconWH + xTextIconDistance;
            contW = Math.max(textL + textW, imgW)
            contL = imgLeftAbs + imgWFitDif
            imgL = 0

            yIn = 1 + markIn
            y2 = yIn + lineLengthY + markOut;
            yOut = y2;
            svgH = yOut + markOut + 1
            contTop = imgTopAbs
            imgTop = 0
            lastHHeight = Math.max(2 * markOut, iconWH, textH)
            lastHHalfHeight = Math.round(lastHHeight / 2)

            svgTop = imgH - yInsideImg - markIn
            iconTop = imgH + lineLengthY - iconWHalfHeight
            textTop = imgH + lineLengthY - textHalfHeight

            contH = imgH + lineLengthY + lastHHalfHeight + 2

            break;
        case "bottomLeft":
            smerY = "bottom"
            smerX = "left"
            xOut = markOut + 1;
            x2 = xOut + lastLineH;
            xIn = xOut + lineLengthX;

            svgW = xIn + markIn + 2
            widthSvgIconText = svgW + iconDistX + iconWH + xTextIconDistance + textW
            if (widthSvgIconText > middleRatio * imgW) {
                imgL = widthSvgIconText - middleRatio * imgW

            } else {
                imgL = 0
            }
            contL = imgLeftAbs - imgL
            contW = imgL + imgW + 3
            svgL = imgL + middleRatio * imgW - svgW
            iconL = svgL - iconDistX - iconWH
            textL = iconL - xTextIconDistance - textW
            yIn = 1 + markIn
            y2 = yIn + lineLengthY + markOut;
            yOut = y2;
            svgH = yOut + markOut + 2
            contTop = imgTopAbs
            imgTop = 0
            lastHHeight = Math.max(2 * markOut, iconWH, textH)
            lastHHalfHeight = Math.round(lastHHeight / 2)

            svgTop = imgH - yInsideImg - markIn
            iconTop = imgH + lineLengthY - iconWHalfHeight
            textTop = imgH + lineLengthY - textHalfHeight

            contH = imgH + lineLengthY + lastHHalfHeight + 2

            break;
        default:
            break;
    }

    const styleImgPosition = {
        left: imgL,
        top: imgTop,
        width: imgW,
        height: imgH,
    }

    const motionDirection = { smerX, smerY, xText: textL, yText: textTop }

    const svgWPx = `${svgW}px`;
    const svgHPx = `${svgH}px`;
    const iconLPx = `${iconL}px`;
    const iconTopPx = `${iconTop}px`;

    const textLPx = `${textL}px`;
    const textTopPx = `${textTop}px`;
    const textWPx = `${textW}px`;
    const textHPx = `${textH}px`;

    const svgLPx = `${svgL}px`;
    const svgTopPx = `${svgTop}px`;

    return { styleImgPosition, svgWPx, svgHPx, svgLPx, svgTopPx, markIn, xIn, yIn, x2, yOut, xOut, markOut, iconLPx, iconTopPx, iconWH, motionDirection, textWPx, textHPx, textLPx, textTopPx, contL, contTop, contW, contH };
}


const getMoveXYOutCont = ({ motionDirection, widthCont, heightCont }) => {
    const { smerX = "", smerY = "" } = motionDirection
    let xOut, yOut

    if (smerX.length > 0) {
        xOut = smerX === "left" ? -widthCont : widthCont
    } else {
        xOut = 0
    }
    if (smerY.length > 0) {
        yOut = smerY === "top" ? -heightCont : heightCont
    } else {
        yOut = 0
    }

    return [ xOut, yOut ]
}


const intervalHoveringSeq = 10000



const AbsImageItem = ({ notInView, imgData, coefX, coefY, coefXforShift, grShiftX, grShiftY, getIconComponent, absImagesNodes, i, refText, handleOnClick, selectedIndex, heightCont, widthCont }) => {

    const { src, icon, orderId } = imgData;
    const baseItemDelay = orderId * 3 // 5
    const theme = useTheme();

    const styleTextDe = {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }

    const [ myPhrase, setPhrase ] = useState({ index: i, lang: "de", text: GERMAN_PHRASES[ i ], style: styleTextDe })
    const refPhrase = useRef(myPhrase)
    const controls = useAnimation()

    const changePhrase = useCallback(async () => {
        const styleTextEn = {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
        const styleTextDe = {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
        const styleTextRu = {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText
        }

        const { index, lang, text } = refPhrase.current

        let nextLang
        let nextI
        let newPhrase
        if (!!text) {
            switch (lang) {
                case "en":
                    nextLang = "de"
                    nextI = index < numberGermanPhrases - 1 ? index + 1 : 0
                    newPhrase = { index: nextI, lang: nextLang, text: GERMAN_PHRASES[ nextI ], style: styleTextDe }
                    break;
                case "ru":
                    nextLang = "en"
                    nextI = index < numberEnglishPhrases - 1 ? index + 1 : 0
                    newPhrase = { index: nextI, lang: nextLang, text: ENGLISH_PHRASES[ nextI ][ 0 ], style: styleTextEn }
                    break;
                case "de":

                    nextLang = "ru"
                    nextI = index < numberRussianPhrases - 1 ? index + 1 : 0
                    newPhrase = { index: nextI, lang: nextLang, text: RUSSIAN_PHRASES[ nextI ][ 0 ], style: styleTextRu }
                    break;
                default:
                    break;
            }
        }

        await controls.start("textChangeBefore")
        setPhrase(newPhrase)
        refPhrase.current = newPhrase
        controls.start("textChangeAfter")

    }, [ controls, theme.palette.info.contrastText, theme.palette.info.main, theme.palette.primary.contrastText, theme.palette.primary.main, theme.palette.secondary.contrastText, theme.palette.secondary.main ]);


    useEffect(() => {
        if (window) {
            let timerChangePhrase
            if (notInView) {
                controls.stop()
                clearInterval(timerChangePhrase)
            } else {
                timerChangePhrase = window.setInterval(changePhrase, 15000 + baseItemDelay * 1000);
            }

            return () => {
                controls.stop()
                clearInterval(timerChangePhrase)
            };
        }
    }, [ changePhrase, baseItemDelay, controls, notInView ]);

    const timeDelayedHovering = 20000 + (orderId + 1) * intervalHoveringSeq

    const positionsForAnim = useMemo(() => getPositionsForAnimEffect({ grShiftX, grShiftY, imgData, coefX, coefY, coefXforShift }), [ grShiftX, grShiftY, imgData, coefX, coefY, coefXforShift ]);

    useEffect(() => {
        if (window) {
            let timer
            if (notInView) {
                controls.stop()
                clearTimeout(timer)
            }
            else {
                controls.start("enter")
                timer = window.setTimeout(() => {
                    controls.start("whileHover")
                }, timeDelayedHovering)
            }

            return () => {
                controls.stop()
                clearTimeout(timer)
            }
        }
    }, [ controls, timeDelayedHovering, notInView ])



    const iconComponent = getIconComponent(icon);

    const imgNode = absImagesNodes.find(node => node.base === src);
    const { name, } = imgNode;
    const image = imgNode.childImageSharp.gatsbyImageData;
    const { backgroundColor: imgMainColor } = image;
    const lightBackgroundColor = emphasize(imgMainColor, 0.3);
    const darkBackgroundColor = emphasize(imgMainColor, 0.8);
    const { styleImgPosition, svgWPx, svgHPx, svgLPx, svgTopPx, markIn, xIn, yIn, x2, yOut, xOut, markOut, iconLPx, iconTopPx, iconWH, motionDirection, textWPx, textHPx, textLPx, textTopPx, contL, contTop, contW, contH } = positionsForAnim // getPositionsForAnimEffect() // positionsForAnim

    const xyOutCont = getMoveXYOutCont({ motionDirection, widthCont, heightCont })

    const styleItemPrimary = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[ 21 ],
    }

    const isSelected = selectedIndex === i

    return (
        <Box component={motion.div}
            layout
            className={`${isSelected ? "selectedSet wrapItemSet" : "wrapItemSet"}`}
            style={{
                left: contL, top: contTop, width: contW, height: contH,
                // opacity: 1
            }}
            variants={varWrapAbsItem({
                notInView,
                palette: theme.palette, baseItemDelay,
                isSelected: isSelected, motionDirection, xyOutCont
            })}
            initial="initial"
            // animate={notInView ? {} : controls}
            animate={controls}
            whileHover="whileHover"
            whileTap="whileTap"
            onAnimationComplete={(e, layer) => {
            }}
            onAnimationStart={(e, layer) => {
            }}
            onHoverStart={(event, info) => { }}
            onHoverEnd={(event, info) => { }}
            onClick={(e) => handleOnClick({ e, iItem: i, controlsItem: controls })}
            key={`absImgItem-${name}---${orderId}`}
        >
            <motion.img
                layout
                src={image.images.fallback.src}
                alt={`${name}`}
                variants={varImg({ notInView, motionDirection })}
                className="imgAbs"
                style={{ ...styleImgPosition }}
                key={`img-${name}---${orderId}`}
            />
            <motion.div
                layout
                className="divSvg"
                style={{ width: svgWPx, height: svgHPx, left: svgLPx, top: svgTopPx, opacity: 0 }}
                variants={varDivSvg({ notInView, motionDirection })}
                key={`${name}-divforsvg`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    key={`${name}-svg`}
                    width={svgWPx}
                    height={svgHPx}
                    fill="none"
                >
                    <motion.circle
                        layout
                        className="svgCircle"

                        variants={varCircle({ notInView, palette: theme.palette, xIn: xIn, yIn: yIn })}
                        r={`${markIn}`}
                        stroke={darkBackgroundColor}
                        strokeDasharray="01"
                        strokeLinecap="round"
                        pathLength={10}
                        strokeWidth={2}
                        fill={lightBackgroundColor}
                        key={`${name}-circle1`}
                    />
                    <motion.path
                        layout
                        variants={varSvgPath({ notInView, imgMainColor })}
                        d={`M ${xIn} ${yIn} L ${x2} ${yOut} L ${xOut} ${yOut}`}
                        // strokeDasharray="01"
                        strokeDasharray="0 1"
                        strokeLinecap="round"
                        fill="none"
                        strokeWidth={2}
                        stroke={imgMainColor}
                        pathLength={10}
                        key={`${name}-varSvgPath`}
                    />
                    <motion.circle
                        layout
                        className="svgCircle"
                        variants={varCircle({ notInView, palette: theme.palette, xIn: xOut, yIn: yOut })}
                        r={`${markOut}`}
                        stroke={imgMainColor}
                        fill={theme.palette.info.main}
                        strokeWidth={2}
                        strokeLinecap="round"
                        pathLength={10}
                        strokeDasharray="01"
                        key={`${name}-circle2`}
                    />
                </svg>
            </motion.div>
            <motion.div
                layout
                style={{ left: iconLPx, top: iconTopPx, width: iconWH, height: iconWH, opacity: 0 }}
                className="divIcon MuiFab-root MuiFab-primary"
                variants={varDivIcon({ notInView, motionDirection })}
                key={`${name}-divIcon`}
            >
                <motion.span
                    layout
                    className="spanIcon MuiFab-label"
                    variants={varSpanIcon({ notInView, motionDirection })}
                    key={`${name}-varSpanIcon`}
                >
                    {iconComponent}
                </motion.span>
            </motion.div>
            <motion.div
                layout
                variants={varPaperText({ notInView, motionDirection })}
                className="paper MuiPaper-root MuiPaper-elevation3 MuiPaper-rounded"
                style={{ left: textLPx, top: textTopPx, width: textWPx, minHeight: textHPx }}
                key={`${name}-motionwrapPaper`}
            >
                <motion.div
                    variants={varDivListItem({ notInView, palette: theme.palette, styleColors: refPhrase.current.style })}
                    className="listItem MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
                    style={styleItemPrimary}
                    tabIndex="0"
                    aria-disabled="false"
                    key={`${name}-varDivListItem`}
                >
                    <motion.div
                        variants={varText({ notInView, palette: theme.palette, styleColors: refPhrase.current.style })}
                        className="listItemText MuiListItemText-root"
                        key={`${name}-varText`}
                    >
                        {myPhrase.text}
                    </motion.div>
                </motion.div>
            </motion.div>
        </Box>
    );
};


const imagesData = getAbsImagesGrouped2()

const AbsImagesGrouped2Layout = ({ data, grContainerHeight, grContainerWidth, topShift }) => {

    const theme = useTheme();

    const [ selectedIndex, setSelectedIndex ] = useState(false)
    const { notInView, isCountedTwice } = useContext(NotInViewContext);

    const handleOnClick = ({ e, iItem, controlsItem }) => {
        e.stopPropagation();
        e.bubbles = false;
        e.preventDefault();

        controlsItem.start("exit").then(() => {
            controlsItem.start("enter", {
                transition: {
                    staggerChildren: 0.1,
                    duration: 0.2,
                }
            })
        })
        iItem === selectedIndex ? setSelectedIndex(false) : setSelectedIndex(iItem)
    }

    const absImagesNodes = data.headerAbsImgFiles1.nodes;

    const refsText = useRef(absImagesNodes.map(() => React.createRef()));
    const grContSemiHeight = grContainerHeight / 2
    const grContSemiWidth = grContainerWidth / 2

    const coefXforShift = grContainerWidth / grWidth
    const coefY = 0.9 * grContainerHeight / grHeight
    const coefX = coefY

    const grShiftX = grContSemiWidth - grSemiWidth
    const grShiftY = grContSemiHeight - grSemiHeight

    const getIconComponent = (iconName) => {
        let iconComp
        switch (iconName) {
            case "VerticalSplit":
                iconComp = (
                    <svg
                        key="VerticalSplitIcon"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                        focusable="false" viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <motion.path
                            variants={varSvgIconPath({ notInView: notInView && !isCountedTwice, palette: theme.palette })}
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary2.main}
                            pathLength={10}
                            // strokeDasharray="01"
                            strokeDasharray="0 1"
                            d="M3 15h8v-2H3v2zm0 4h8v-2H3v2zm0-8h8V9H3v2zm0-6v2h8V5H3zm10 0h8v14h-8V5z"
                            key={`varSvgIconPath-${iconName}`}
                        >
                        </motion.path>
                    </svg>)
                break;
            case "PhotoLibrary":
                iconComp = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        key="PhotoLibraryIcon"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        fill="none"
                    >
                        <motion.path
                            d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"
                            variants={varSvgIconPath({ notInView: notInView && !isCountedTwice, palette: theme.palette })}
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary2.main}
                            pathLength={10}
                            // strokeDasharray="01"
                            strokeDasharray="0 1"
                            key={`varSvgIconPath-${iconName}`}
                        >
                        </motion.path>
                    </svg>
                )
                break;
            case "Devices":
                iconComp = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        key="DevicesIcon"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        fill="none"
                    >
                        <motion.path
                            d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"
                            variants={varSvgIconPath({ notInView: notInView && !isCountedTwice, palette: theme.palette })}
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary2.main}
                            pathLength={10}
                            // strokeDasharray="01"
                            strokeDasharray="0 1"
                            key={`varSvgIconPath-${iconName}`}
                        >
                        </motion.path>
                    </svg>
                )
                break;
            case "Room":
                iconComp = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        key="RoomIcon"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        fill="none"
                    >
                        <motion.path
                            d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                            variants={varSvgIconPath({ notInView: notInView && !isCountedTwice, palette: theme.palette })}
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary2.main}
                            pathLength={10}
                            // strokeDasharray="01"
                            strokeDasharray="0 1"
                            key={`varSvgIconPath-${iconName}`}
                        >
                        </motion.path>
                    </svg>
                )
                break;
            case "Speed":
                iconComp = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        key="SpeedIcon"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                        focusable="false" viewBox="0 0 24 24"
                        aria-hidden="true"
                        fill="none"
                    >
                        <motion.path
                            variants={varSvgIconPath({ notInView: notInView && !isCountedTwice, palette: theme.palette })}
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary2.main}
                            pathLength={10}
                            // strokeDasharray="01"
                            strokeDasharray="0 1"
                            d="M20.38 8.57l-1.23 1.85a8 8 0 01-.22 7.58H5.07A8 8 0 0115.58 6.85l1.85-1.23A10 10 0 003.35 19a2 2 0 001.72 1h13.85a2 2 0 001.74-1 10 10 0 00-.27-10.44zm-9.79 6.84a2 2 0 002.83 0l5.66-8.49-8.49 5.66a2 2 0 000 2.83z"
                            key={`varSvgIconPath-${iconName}`}
                        >
                        </motion.path>
                    </svg>
                )
                break;
            case "MobileFriendly":
                iconComp = (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        key="MobileFriendlyIcon"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        fill="none"
                    >
                        <motion.path
                            d="M19 1H9c-1.1 0-2 .9-2 2v3h2V4h10v16H9v-2H7v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zM7.01 13.47l-2.55-2.55-1.27 1.27L7 16l7.19-7.19-1.27-1.27z"
                            variants={varSvgIconPath({ notInView: notInView && !isCountedTwice, palette: theme.palette })}
                            stroke={theme.palette.primary.main}
                            fill={theme.palette.secondary2.main}
                            pathLength={10}
                            // strokeDasharray="01"
                            strokeDasharray="0 1"
                            key={`varSvgIconPath-${iconName}`}
                        >
                        </motion.path>
                    </svg>
                )
                break;

            default:
                break;
        }
        return iconComp
    }

    const arrImgsGrouped = imagesData.map((imgData, i) => {
        const refText = refsText.current[ i ]
        return AbsImageItem({ notInView: notInView && !isCountedTwice, imgData, coefX, coefY, coefXforShift, grShiftX, grShiftY, getIconComponent, absImagesNodes, i, refText, handleOnClick, selectedIndex, heightCont: grContainerHeight, widthCont: grContainerWidth });
    });

    return (
        <Box component={motion.div}
            sx={styleSxWrapAbsItem}
            variants={varWrapAbs}
            initial={(notInView && !isCountedTwice) ? {} : "initial"}
            // animate={notInView ? {} : "animate"}
            animate={(notInView && !isCountedTwice) ? {} : "animate"}
            style={{
                position: "absolute",
                top: topShift,
                height: "inherit",
                width: "100%",
            }}
            key="varWrapAbs"
        >
            {arrImgsGrouped}
        </Box>
    )
}



function AbsImagesGrouped2(props) {
    return (
        <StaticQuery
            query={graphql`{
                headerAbsImgFiles1: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "galleries/headerAbsImagesGroupedForEffect2"}}) {
                nodes {
                    id
                    base
                    name
                    childImageSharp {
                    id
                    gatsbyImageData(layout: FIXED)
            
                    }
                }
    }
  }
  `}
            render={(data) => < AbsImagesGrouped2Layout data={data} {...props} />}
        />
    )
}


export default React.memo(AbsImagesGrouped2)

